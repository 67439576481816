import React from 'react';
import ReactDOM from 'react-dom';
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./app/keycloak"
import { BrowserRouter } from 'react-router-dom';
import App from './app/App';
import "./i18n";
import * as serviceWorker from './serviceWorker';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

ReactDOM.render( 
  <BrowserRouter basename="/">
    <ReactKeycloakProvider initOptions={{ onLoad: 'login-required' }} authClient={keycloak}>
    <App />
    </ReactKeycloakProvider>
  </BrowserRouter>
, document.getElementById('root'));

serviceWorker.unregister();
