import React from 'react'
import Pagination from 'rc-pagination';


export default function PaginationRight(props) {
    const local = {
        // Options.jsx
        items_per_page: '',
        jump_to: 'Goto',
        jump_to_confirm: '',
        page: '',

        // Pagination.jsx
        prev_page: '',
        next_page: '',
        prev_5: '',
        next_5: '',
        prev_3: '',
        next_3: '',
    }
    return (
        <div className="wmio-pagination-right pagination-panel">
            <Pagination
                defaultCurrent={props.currentPage}
                showPrevNextJumpers={true}
                defaultPageSize={props.limit}
                showQuickJumper={false}
                current={props.currentPage}
                onChange={props.onChange}
                total={props.count}
                hideOnSinglePage={true}
                locale={local}
            />
        </div>
    )
}