import React, { Component, Suspense, lazy } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';
import CertificateValidity from './alerts/CertificateValidity';
import TenantIssues from './tenantIssues/tenantIssues';
import PrivateRoute from "./helpers/PrivateRoute";

const Map = lazy(() => import("./map/map"))
const TenantsOverview = lazy(() => import('./tenantsOverview/tenantsOverview'));
const CustomersOverview = lazy(() => import('./tenantsOverview/customersOverview'));
const PreprodTenantsOverview = lazy(() => import('./tenantsOverview/preprodTenantsOverview'));
const CloudOpsTenantsOverview = lazy(() => import('./tenantsOverview/CloudOpsTenantsOverview'));
const ViewDetails = lazy(() => import('./viewDetails/viewDetails'));
const ViewCustomerDetails = lazy(() => import('./viewDetails/viewCustomerDetails'));
const TenantsProvisioning = lazy(() => import('./tenantsProvisioning/tenantsProvisioning'));
const AlertDetails = lazy(() => import('./alerts/alert-details'));
const AlertMisConfigTenant = lazy(() => import('./alerts/alertMisconfigTeannt'));
const Help = lazy(() => import('./help/help'))


class AppRoutes extends Component {
  render() {
    return (
      <PrivateRoute>
      <Suspense fallback={<Spinner />} {...this.props}>
        <Switch {...this.props}>
          <Route exact path="/" render={() => <Map {...this.props} /> }/>
          <Route exact path={["/tenant-overview", "/tenant-overview/:region", "/tenant-overview/:env/:tier", "/tenant-overview/:env/:tier/:region", "/tenant-overview/:env/:tier/:region/:productStatus"]} component={TenantsOverview} />
          <Route exact path={["/tenant-preprod-overview"]} component={PreprodTenantsOverview} />
          <Route exact path={["/customers-overview", "/customers-overview/:region"]} component={CustomersOverview} />
          <Route exact path={["/tenant-cloudops"]} component={CloudOpsTenantsOverview} />
          <Route exact path={["/view-details/:tenantName"]} component={ViewDetails} />
          <Route exact path={["/view-details/:env/:tenantName"]} component={ViewDetails} />
          <Route exact path={["/view-customer-details/:domain"]} component={ViewCustomerDetails} />
          {/* <Route exact path={["/view-details/:company/issues/:severity"]} component={TenantIssues} /> */}

          <Route exact path={["/tenant-provisioning"]} component={TenantsProvisioning} />
          <Route exact path={["/alert/certificate-validity", "/alert/certificate-validity/:status/:region"]} component={CertificateValidity} />
          <Route exact path={["/alert/misconfigured-tenant", "/alert/misconfigured-tenant/:region"]} component={AlertMisConfigTenant} />
          <Route exact path={["/alert/:last", "/alert/:next", "/alert/:last/:region", "/alert/:next/:region"]} component={AlertDetails} />

          <Route exact path={["/help"]} component={Help} />
          <Redirect to="/" />
        </Switch>
      </Suspense></PrivateRoute>
    );
  }
}

export default AppRoutes;