import React, { useEffect, useState, useRef } from 'react'
import { ProgressBar, Dropdown } from 'react-bootstrap';
import Spinner from '../shared/Spinner';
import PaginationWrapper from '../shared/pagination/paginationWrapper';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import ReactTooltip from 'react-tooltip';
var _ = require('lodash')


const TenantIssues = (props) => {

  const [tag, setTag] = useState({})
  const [showLoader, setShowLoader] = useState(true)
  const [dropDownList, setDropDownList] = useState({ severity: [{ name: 'Critical' }, { name: 'High' }, { name: 'Medium' }, { name: 'Low' }], status: [{ name: "New" }, { name: "In triage" }, { name: "Triaged" }, { name: "Open" }, { name: "Fixed" }, { name: "Follow-up" }, { name: "Closed" }, { name: "Deferred" }, { name: "Completed" }] })
  const [issueList, setIssueList] = useState([]);
  const [paginationSetting, setPaginationSetting] = useState({ count: 0, currentPage: 1, limit: 20, skip: 0 })
  const [noData, setNoData] = useState('')


  // const severityData = ['Critical', 'High', 'Medium', 'Low'];
  // const statusData = ['New', 'In triage', 'Triaged', 'Open', 'Fixed', 'Follow-up', 'Closed', 'Deferred'];



  const range = () => {
    let end = paginationSetting.currentPage * paginationSetting.limit
    let start = (end - paginationSetting.limit) + 1

    if (end > paginationSetting.count) {
      end = paginationSetting.count
    }

    return { start, end }
  }

  const handlePageChange = (pageNumber) => {
    let calculatedSkip = calculateSkip(pageNumber, paginationSetting.limit);
    setPaginationSetting({ ...paginationSetting, currentPage: pageNumber, skip: calculatedSkip })

  }

  const calculateSkip = (currentPage, limit) => {
    return ((currentPage - 1) * limit);
  }

  const viewClickHandler = (data) => {
    window.open(data.Link, '_blank', 'noopener,noreferrer');
  }

  const onDropDownClickHandler = (obj, key) => {
    let cloneTag = _.cloneDeep(tag)
    let ClonedropDownList = _.cloneDeep(dropDownList)
    if (cloneTag[key] !== undefined) {
      cloneTag[key].push(obj)
    } else {
      cloneTag[key] = []
      cloneTag[key].push(obj)
    }
    // cloneTag[key] !== undefined ? cloneTag[key].push(obj) = [
    // (cloneTag[key] || (cloneTag[key] = [])).push(obj);
    // cloneTag[key].push(obj)

    let arr = {}
    arr[key] = _.filter(ClonedropDownList[key], (cloneObj) => {
      return cloneObj.name !== obj.name
    })

    setTag(cloneTag)
    setDropDownList({ ...ClonedropDownList, ...arr })
  }

  const getIssuesData = (query) => {
    fetch(`/api/v1/details/graph/${props.match.params.company}/issues?${query ? query : "severity=" + props.match.params.severity}`)
      .then((res) => res.json())
      .then(
        (response) => {
          setTimeout(() => {
            setIssueList(response);
            setShowLoader(false);

          }, 1000);
          setPaginationSetting({ ...paginationSetting, count: response.length })
        },
        (error) => {
          console.log('Error', error);
        }
      );
  };

  const tagRemoveClickHandler = (data, key, findLast) => {
    let cloneTag = _.cloneDeep(tag)
    let ClonedropDownList = _.cloneDeep(dropDownList)

    _.remove(cloneTag[key], (removeObj) => {
      return removeObj.name === data.name
    })

    if (!cloneTag[key].length) {
      delete cloneTag[key]
    }

    ClonedropDownList[key].push(data)
    // let cloneData = {data, ...ClonedropDownList[data.type]}
    setTag(cloneTag)
    setDropDownList(ClonedropDownList)

    if (_.isEmpty(cloneTag)) {
      setShowLoader(true)
      getIssuesData()
    }
  }

  // const onPageLoad = () => {
  //   let ClonedropDownList = _.cloneDeep(dropDownList)
  //   if (!props.match.params.hasOwnProperty('severity')) {
  //     setTag({})
  //     setDropDownList(ClonedropDownList)
  //     return
  //   }



  //   let findSeverity = _.find(ClonedropDownList.severity, { name: props.match.params.severity })
  //   let cloneTag = _.cloneDeep(tag)
  //   if (findSeverity) {
  //     cloneTag['severity'] = []
  //     cloneTag['severity'].push(findSeverity)
  //     let arr = {}

  //     arr['severity'] = _.filter(ClonedropDownList['severity'], (cloneObj) => {
  //       return cloneObj.name !== findSeverity.name
  //     })


  //     setDropDownList({ ...ClonedropDownList, ...arr })
  //     setTag(cloneTag)
  //   }
  // }

  useEffect(() => {
    // onPageLoad()
    getIssuesData();
  }, [props.location.company, props.location.severity]);

  const queryString = () => {
    let cloneTagData = _.cloneDeep(tag)
    let query = ''
    query = Object.keys(cloneTagData).map((key) => {
      if (cloneTagData[key].length) {
        let cloneMapData = _.map(cloneTagData[key], 'name').toString();
        return key + '=' + cloneMapData
      }
    }).join('&')

    return query
  }

  const applyClickHandler = () => {

    let query = queryString()

    setShowLoader(true)
    getIssuesData(query)
  }

  const showDropDownList = (key) => {

    return dropDownList[key].map((obj) => {
      return (
        <Dropdown.Item onClick={onDropDownClickHandler.bind(null, obj, key)}>{obj.name}</Dropdown.Item>
      )
    })
  }

  return (
    <div className='overview'>
      <div className="col-lg-12 grid-margin stretch-card inner-overview-container">


        <div className="card">
          <div className="card-body default-setng">
            <h4 className="card-title mb-0 ">Support Tickets</h4>
            <a href={`data:text/json;charset=utf-8,${encodeURIComponent(JSON.stringify(issueList))}`} download="CustomerIssues.json" data-for='export' data-tip="Export customer issues" className="btn btn-inverse-secondary export-btn tenant-btn">
              Export
              {/* <i className='mdi mdi-file-export'></i> */}
              <ReactTooltip id='export' place='right' effect='solid'></ReactTooltip>
            </a>
            <div className="row">
              {/* <div className='col-lg-3'> */}
              {/* <span>Project</span> */}

              {/* <Dropdown>
                      <Dropdown.Toggle variant="btn btn-outline-secondary dropdown-toggle" id="dropdownMenuButton4">
                        Product
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {dropDownList && dropDownList['product'].length ?
                          showDropDownList('product')
                          :
                          <Dropdown.Item className='no-data'>No Product</Dropdown.Item>
                        }
                      </Dropdown.Menu>
                    </Dropdown> */}

              {/* </div> */}
              <div className='col-lg-3'>
                <Dropdown>
                  <Dropdown.Toggle variant="btn btn-outline-secondary dropdown-toggle" id="dropdownMenuButton4">
                    Severity
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {dropDownList && dropDownList['severity'].length ?
                      showDropDownList('severity')
                      :
                      <Dropdown.Item className='no-data'>No Severity</Dropdown.Item>
                    }
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className='col-lg-3'>
                <Dropdown>
                  <Dropdown.Toggle variant="btn btn-outline-secondary dropdown-toggle" id="dropdownMenuButton4">
                    Status
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {dropDownList && dropDownList['status'].length ?
                      showDropDownList('status')
                      :
                      <Dropdown.Item className='no-data'>No Status</Dropdown.Item>
                    }
                  </Dropdown.Menu>
                </Dropdown>

              </div>
              <div className='col-lg-3'>
                {/* <span>Tier</span> */}

                {/* <Dropdown>
                      <Dropdown.Toggle variant="btn btn-outline-secondary dropdown-toggle" id="dropdownMenuButton4">
                        Tier
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {dropDownList && dropDownList['tier'].length ?
                          showDropDownList('tier')
                          :
                          <Dropdown.Item className='no-data'>No tier</Dropdown.Item>
                        }
                      </Dropdown.Menu>
                    </Dropdown> */}

              </div>


            </div>
            <div className='row'>
              <div className='tag-label'>
                {tag && !_.isEmpty(tag) ?
                  Object.keys(tag).map((key, i) => {
                    if (tag[key].length) {
                      return tag[key].map((obj, i) => {
                        return (
                          <div className='tag-ui'>
                            <span className='tag-type'>{key}</span>
                            <span className='tag-name'>{obj.name}</span>
                            <span className={`tag-cross mdi mdi-close icon-sm`} onClick={tagRemoveClickHandler.bind(null, obj, key)}></span>
                          </div>
                          // ${props.match.params.env === obj.name || props.match.params.region === obj.name ? "disabled-click" : ""}`

                        )

                      })
                    }
                  })
                  : null}
              </div>
            </div>

            {tag && !_.isEmpty(tag) ?
              <div className='btn-apply'>
                <button type="button" className="btn btn-inverse-dark" onClick={applyClickHandler}>Apply</button>
              </div>

              : null}

            {/* disabled={tag['product'][0].name === props.match.params.env && tag['region'][0].name === props.match.params.region ? tag['product'].length > 1 || tag['productStatus'].length > 1 || tag['tier'].length > 1 || tag['region'].length > 1 ? false : true : false} */}

            <div className='table-brb'>
              <div className="table-responsive">
                <table className={showLoader ? "table table-bordered min-hght" : "table table-bordered"}>
                  <thead>
                    <tr>
                      <th> # </th>
                      <th> Issue key</th>
                      <th> Summary </th>
                      <th> Priority </th>
                      <th> Created on </th>
                      <th> Severity </th>
                      <th> Status </th>
                      <th> Resolution time </th>
                    </tr>
                  </thead>
                  <tbody>
                    {showLoader ?
                      <div className={tag && !_.isEmpty(tag) ? 'spinner-table' : 'spinner-table top-0'}>
                        <Spinner />

                      </div>
                      :
                      issueList && issueList.length ?

                        _.slice(_.map(issueList, (obj, i) => {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              <td className='active' onClick={viewClickHandler.bind(null, obj)}>{obj['Issue key']}</td>
                              <td>{obj['Summary']}</td>
                              <td>{obj['Priority']}</td>
                              <td>{obj['Created']}</td>
                              <td>{obj['Severity']}</td>
                              <td>Completed</td>
                              <td>10 Days</td>
                            </tr>
                          )
                        }), paginationSetting.skip, paginationSetting.limit + paginationSetting.skip)
                        :
                        null
                    }
                  </tbody>



                </table>
                {!showLoader && issueList && !issueList.length ? <span className='no-data-found'>{`${noData || "No data found"}`}</span> : null}
                {issueList && issueList.length ?
                  <PaginationWrapper
                    currentPage={paginationSetting.currentPage}
                    count={paginationSetting.count}
                    current={paginationSetting.currentPage}
                    onChange={handlePageChange}
                    limit={paginationSetting.limit}
                    range={range()}
                  />
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </div>
  )
}

export default TenantIssues;
