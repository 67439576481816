import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import './App.scss';
import AppRoutes from './AppRoutes';
import Navbar from './shared/Navbar';
import Sidebar from './shared/Sidebar';
import SettingsPanel from './shared/SettingsPanel';
import Footer from './shared/Footer';
import { withTranslation } from "react-i18next";
import { func } from 'prop-types';
import { useKeycloak } from '@react-keycloak/web';

const App = ({ location, i18n }) => {
  const [isFullPageLayout, setIsFullPageLayout] = useState(false);
  const [provider, setProvider] = useState("global");
  const [selectRegion, setSelectRegion] = useState("");

  const { keycloak, initialized } = useKeycloak();

  const providerSelected = (provider) => {
    setProvider(provider);
  };

  const regionSelected = (region) => {
    setSelectRegion(region);
  };

  const onRouteChanged = () => {
    const body = document.querySelector('body');
    const pageBodyWrapper = document.querySelector('.page-body-wrapper');

    if (location.pathname === '/layout/RtlLayout') {
      body.classList.add('rtl');
      i18n.changeLanguage('ar');
    } else {
      body.classList.remove('rtl');
      i18n.changeLanguage('en');
    }

    window.scrollTo(0, 0);
    const fullPageLayoutRoutes = [];

    const isFullPage = fullPageLayoutRoutes.includes(location.pathname);
    setIsFullPageLayout(isFullPage);

    if (pageBodyWrapper) {
      pageBodyWrapper.classList.toggle('full-page-wrapper', isFullPage);
    }
  };


  useEffect(() => {
    onRouteChanged();
  }, [location]);

  let navbarComponent = !isFullPageLayout && keycloak.authenticated ? <Navbar /> : '';
  let sidebarComponent = !isFullPageLayout && keycloak.authenticated ? <Sidebar /> : '';
  let SettingsPanelComponent = !isFullPageLayout && keycloak.authenticated ? <SettingsPanel /> : '';
  let footerComponent = !isFullPageLayout && keycloak.authenticated ? <Footer /> : '';

  return (
    <div className="container-scroller">
      {navbarComponent}
      <div className="container-fluid page-body-wrapper">
        {sidebarComponent}
        <div className="main-panel">
          <div className="content-wrapper" id="wrapperd-uiniq">
            <AppRoutes selectRegion={selectRegion} provider={provider} providerSelected={providerSelected} regionSelected={regionSelected} />
            {SettingsPanelComponent}
          </div>
          {/* {footerComponent} */}
        </div>
      </div>
    </div>
  );
};

export default withTranslation()(withRouter(App));
