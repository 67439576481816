import React, { useEffect, useState, useRef } from 'react'
import { ProgressBar, Dropdown } from 'react-bootstrap';
import { exportToExcel } from 'react-json-to-excel';
import Spinner from '../shared/Spinner';
import PaginationWrapper from '../shared/pagination/paginationWrapper';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import qs from 'qs';
import ReactTooltip from 'react-tooltip';
var _ = require('lodash');


const CertificateValidity = (props) => {

  const [tag, setTag] = useState({})
  const [showLoader, setShowLoader] = useState(true)
  const [dropDownList, setDropDownList] = useState({
    product: [
      { name: 'API Control Plane' },
      { name: 'API Gateway' },
      { name: 'B2B' },
      { name: 'Cloud Container' },
      { name: 'Developer Portal' },
      { name: 'E2E Monitoring' },
      { name: 'Integration' },
      { name: 'Metering' },
      { name: 'SAG Cloud' },
    ],
    region: [
      { "name": "AWS AU" },
      { "name": "AWS EU" },
      { "name": "AWS US" },
      { "name": "AZ AU" },
      { "name": "AZ EU" },
      { "name": "AZ US" },
      { "name": "AZ US1" }
    ],
    stage: [
      { "name": "Preprod" },
      { "name": "Prod" }
    ],
    status: [
      { "name": "Active" },
      { "name": "Expired" },
      { "name": "Expiring" }
    ]
  });
  const [certificateValidity, setCertificateValidity] = useState([]);
  const [paginationSetting, setPaginationSetting] = useState({ count: 0, currentPage: 1, limit: 20, skip: 0 })
  const [noData, setNoData] = useState('')

  const [order, setOrder] = useState("ASC");
  const sort = (column) => {
    let sorted;
    if (order === "ASC") {
      if (column === "expiry") {
        sorted = [...certificateValidity].sort((a, b) => {
          const dateA = new Date(a[column]);
          const dateB = new Date(b[column]);
          return dateA - dateB;
        });
      }
      else if (column === "countdown") {
        sorted = [...certificateValidity].sort((a, b) =>
          a[column] > b[column] ? 1 : -1
        );
      }
      else {
        sorted = [...certificateValidity].sort((a, b) =>
          a[column].toLowerCase() > b[column].toLowerCase() ? 1 : -1
        );
      }
      setCertificateValidity(sorted);
      setOrder("DES");
    }
    if (order === "DES") {
      if (column === "expiry") {
        sorted = [...certificateValidity].sort((a, b) => {
          const dateA = new Date(a[column]);
          const dateB = new Date(b[column]);
          return dateB - dateA;
        });
      }
      else if (column === "countdown") {
        sorted = [...certificateValidity].sort((a, b) =>
          a[column] < b[column] ? 1 : -1
        );
      }
      else {
        sorted = [...certificateValidity].sort((a, b) =>
          a[column].toLowerCase() < b[column].toLowerCase() ? 1 : -1
        );
      }
      setCertificateValidity(sorted);
      setOrder("ASC");
    }
  }

  const range = () => {
    let end = paginationSetting.currentPage * paginationSetting.limit
    let start = (end - paginationSetting.limit) + 1

    if (end > paginationSetting.count) {
      end = paginationSetting.count
    }

    return { start, end }
  }

  const handlePageChange = (pageNumber) => {
    let calculatedSkip = calculateSkip(pageNumber, paginationSetting.limit);
    setPaginationSetting({ ...paginationSetting, currentPage: pageNumber, skip: calculatedSkip })

  }

  const calculateSkip = (currentPage, limit) => {
    return ((currentPage - 1) * limit);
  }

  const tagRemoveClickHandler = (data, key, findLast) => {
    let cloneTag = _.cloneDeep(tag)
    let ClonedropDownList = _.cloneDeep(dropDownList)

    _.remove(cloneTag[key], (removeObj) => {
      return removeObj.name === data.name
    })

    if (!cloneTag[key].length) {
      delete cloneTag[key]
    }

    ClonedropDownList[key].push(data)
    // let cloneData = {data, ...ClonedropDownList[data.type]}
    setTag(cloneTag)
    setDropDownList(ClonedropDownList)

    if (_.isEmpty(cloneTag)) {
      setShowLoader(true)
      getCertificateValidity()
    }
  }

  const onDropDownClickHandler = async (obj, key) => {
    let cloneTag = _.cloneDeep(tag)
    let ClonedropDownList = _.cloneDeep(dropDownList)
    if (!cloneTag[key]) {
      cloneTag[key] = [];
    }
    cloneTag[key].push(obj);
    let arr = {}
    arr[key] = _.filter(ClonedropDownList[key], (cloneObj) => {
      return cloneObj.name !== obj.name
    })

    setTag(cloneTag);
    setDropDownList({ ...ClonedropDownList, ...arr });
  }

  const onPageLoad = async () => {

    if (!props.match.params.hasOwnProperty('region') || !props.match.params.hasOwnProperty('status')) {
      setTag({})
      return
    }

    let cloneTag = _.cloneDeep(tag)
    let ClonedropDownList = _.cloneDeep(dropDownList)
    let arr = {}

    if (props.match.params.hasOwnProperty('region')) {
      if (!cloneTag["region"]) {
        cloneTag["region"] = [];
      }
      if (props.match.params.region !== "global") {
        let findRegion = { name: props.match.params.region }
        cloneTag["region"].push(findRegion);
        arr["region"] = _.filter(ClonedropDownList["region"], (cloneObj) => {
          return cloneObj.name !== findRegion.name
        })
      }

    }
    if (props.match.params.hasOwnProperty('status')) {
      if (!cloneTag["status"]) {
        cloneTag["status"] = [];
      }
      let tempStatus = props.match.params.status
      let findStatus = { name: tempStatus.charAt(0).toUpperCase() + tempStatus.slice(1) }
      cloneTag["status"].push(findStatus);
      arr["status"] = _.filter(ClonedropDownList["status"], (cloneObj) => {
        return cloneObj.name !== findStatus.name
      })
    }
    setTag(cloneTag);
    setDropDownList({ ...ClonedropDownList, ...arr });
  }

  const getCertificateValidity = (query) => {
    const regionName = qs.parse(props.location.search, { ignoreQueryPrefix: true });
    const queryParams = Object.keys(regionName).length || query ? query || `region=${regionName.region}` : "";
    fetch(`/api/v1/dashboard/certificateValidity${queryParams ? `?${queryParams}` : ""}`)
      .then((res) => res.json())
      .then(
        (response) => {
          setTimeout(() => {
            setCertificateValidity(response);
            setShowLoader(false);
          }, 1000);
          setPaginationSetting({ ...paginationSetting, count: response.length })
        },
        (error) => {
          console.log('Error', error);
        }
      );
  };

  useEffect(() => {
    onPageLoad();
    const { region, status } = props?.match?.params || {};
    const query = [];

    if (region) {
      query.push(`region=${region}`);
    }
    if (status) {
      query.push(`status=${status}`);
    }
    const queryString = query.length > 0 ? query.join('&') : '';
    getCertificateValidity(queryString);
  }, []);

  const queryString = () => {
    let cloneTagData = _.cloneDeep(tag)
    let query = ''
    query = Object.keys(cloneTagData).map((key) => {
      if (cloneTagData[key].length) {
        let cloneMapData = _.map(cloneTagData[key], 'name').toString();
        return key + '=' + cloneMapData
      }
    }).join('&')

    return query
  }

  const applyClickHandler = () => {

    let query = queryString()

    setShowLoader(true)
    getCertificateValidity(query)
  }

  const showDropDownList = (key) => {

    return dropDownList[key].map((obj) => {
      return (
        <Dropdown.Item onClick={onDropDownClickHandler.bind(null, obj, key)}>{obj.name}</Dropdown.Item>
      )
    })
  }

  return (
    <div className='overview'>
      <div className="col-lg-12 grid-margin stretch-card inner-overview-container">


        <div className="card">
          <div className="card-body default-setng">
            <h4 className="card-title mb-0 ">Certificate Validity</h4>
            <p className='card-description'>List of Certificates and their Validity</p>
            <button onClick={() => exportToExcel(certificateValidity, 'CertificateValidity')} className='jsonToExcel'>
              Export
            </button>
            <div className="row">
              <div className='col-lg-3'>
                <Dropdown>
                  <Dropdown.Toggle variant="btn btn-outline-secondary dropdown-toggle" id="dropdownMenuButton4">
                    Product
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {dropDownList && dropDownList['product'].length ?
                      showDropDownList('product')
                      :
                      <Dropdown.Item className='no-data'>No Products</Dropdown.Item>
                    }
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className='col-lg-3'>
                <Dropdown>
                  <Dropdown.Toggle variant="btn btn-outline-secondary dropdown-toggle" id="dropdownMenuButton4">
                    Region
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {dropDownList && dropDownList['region'].length ?
                      showDropDownList('region')
                      :
                      <Dropdown.Item className='no-data'>No Regions</Dropdown.Item>
                    }
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className='col-lg-3'>
                <Dropdown>
                  <Dropdown.Toggle variant="btn btn-outline-secondary dropdown-toggle" id="dropdownMenuButton4">
                    Stage
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {dropDownList && dropDownList['stage'].length ?
                      showDropDownList('stage')
                      :
                      <Dropdown.Item className='no-data'>No Stages</Dropdown.Item>
                    }
                  </Dropdown.Menu>
                </Dropdown>
              </div>

              <div className='col-lg-3'>
                <Dropdown>
                  <Dropdown.Toggle variant="btn btn-outline-secondary dropdown-toggle" id="dropdownMenuButton4">
                    Status
                  </Dropdown.Toggle>
                  <Dropdown.Menu>
                    {dropDownList && dropDownList['status'].length ?
                      showDropDownList('status')
                      :
                      <Dropdown.Item className='no-data'>No Status</Dropdown.Item>
                    }
                  </Dropdown.Menu>
                </Dropdown>
              </div>

            </div>
            <div className='row'>
              <div className='tag-label'>
                {tag && !_.isEmpty(tag) ?
                  Object.keys(tag).map((key, i) => {
                    if (tag[key].length) {
                      return tag[key].map((obj, i) => {
                        return (
                          <div className='tag-ui'>
                            <span className='tag-type'>{key}</span>
                            <span className='tag-name'>{obj.name}</span>
                            <span className={`tag-cross mdi mdi-close icon-sm`} onClick={tagRemoveClickHandler.bind(null, obj, key)}></span>
                          </div>
                          // ${props.match.params.env === obj.name || props.match.params.region === obj.name ? "disabled-click" : ""}`

                        )

                      })
                    }
                  })
                  : null}
              </div>
            </div>

            {tag && !_.isEmpty(tag) ?
              <div className='btn-apply'>
                <button type="button" className="btn btn-inverse-dark" onClick={applyClickHandler}>Apply</button>
              </div>

              : null}

            {/* disabled={tag['product'][0].name === props.match.params.env && tag['region'][0].name === props.match.params.region ? tag['product'].length > 1 || tag['productStatus'].length > 1 || tag['tier'].length > 1 || tag['region'].length > 1 ? false : true : false} */}

            <div className='table-brb'>
              <div className="table-responsive">
                <table className={showLoader ? "table table-bordered min-hght" : "table table-bordered"}>
                  <thead>
                    <tr>
                      <th> # </th>
                      <th> Product <i className='mdi mdi-sort' onClick={() => sort("product")}></i></th>
                      <th> Region <i className='mdi mdi-sort' onClick={() => sort("region")}></i></th>
                      <th> Stage <i className='mdi mdi-sort' onClick={() => sort("stage")}></i></th>
                      <th> Domain <i className='mdi mdi-sort' onClick={() => sort("domain")}></i></th>
                      <th> Expiry <i className='mdi mdi-sort' onClick={() => sort("expiry")}></i></th>
                      <th> Countdown <i className='mdi mdi-sort' onClick={() => sort("countdown")}></i></th>
                    </tr>
                  </thead>
                  <tbody>
                    {showLoader ?
                      <div className={tag && !_.isEmpty(tag) ? 'spinner-table' : 'spinner-table top-0'}>
                        <Spinner />

                      </div>
                      :
                      certificateValidity && certificateValidity.length ?

                        _.slice(_.map(certificateValidity, (obj, i) => {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{obj['product']}</td>
                              <td>{obj['region']}</td>
                              <td>{obj['stage']}</td>
                              <td>{obj['domain']}</td>
                              <td>{obj['expiry']}</td>
                              {obj['countdown'] > 30 ? (
                                <td><div id="countdown" style={{ background: "#6ba733" }}>{obj['countdown']}</div></td>
                              ) : (
                                <td><div id="countdown" style={{ background: "#ef233c" }}>{obj['countdown']}</div></td>
                              )}
                            </tr>
                          )
                        }), paginationSetting.skip, paginationSetting.limit + paginationSetting.skip)
                        :
                        null
                    }
                  </tbody>



                </table>
                {!showLoader && certificateValidity && !certificateValidity.length ? <span className='no-data-found'>{`${noData || "No data found"}`}</span> : null}
                {certificateValidity && certificateValidity.length ?
                  <PaginationWrapper
                    currentPage={paginationSetting.currentPage}
                    count={paginationSetting.count}
                    current={paginationSetting.currentPage}
                    onChange={handlePageChange}
                    limit={paginationSetting.limit}
                    range={range()}
                  />
                  : null}
              </div>
            </div>
          </div>
        </div>
      </div>
      <NotificationContainer />
    </div>
  )
}

export default CertificateValidity;
