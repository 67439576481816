import React, { useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { capitalize } from 'lodash';

const Navbar = ({ history, location }) => {
  const [dropdownSelection, setDropdownSelection] = useState('tenantName');
  const [searchText, setSearchText] = useState('');

  const searchBar = useRef();

  const dropClickHandler = (obj) => {
    setDropdownSelection(obj);
    searchBar.current.focus();
  }

  const searchChangeHandler = (e) => {
    if (!e.currentTarget.value.length) {
      if (location.pathname === "/") {
        history.replace('/');
      } else {
        if (location.pathname === "/tenant-overview") {
          history.replace(`/tenant-overview`);
        }
      }
    }
    setSearchText(e.currentTarget.value);
  }

  const keyChangeHandler = (e) => {
    if (e.key === "Enter" || e.key.code === '13') {
      if (searchText.length) {
        history.push(`/tenant-overview?criteria=${dropdownSelection}&search=${searchText}`);
      }
    }
  }

  const toggleOffcanvas = () => {
    document.querySelector('.sidebar-offcanvas').classList.toggle('active');
  }

  const toggleRightSidebar = () => {
    document.querySelector('.right-sidebar').classList.toggle('open');
  }

  const capitalizeFirstLetter = (str) => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return (
    <nav className="navbar col-lg-12 col-12 p-lg-0 fixed-top d-flex flex-row">
      <div className="navbar-menu-wrapper d-flex align-items-center justify-content-between">
        <Link to="/" className="navbar-brand brand-logo-mini align-self-center d-lg-none" href="!#" onClick={evt => evt.preventDefault()}><i className='mdi mdi-home'></i></Link>
        <Link to='/' className='supervisor-sag'><img src={require("../../assets/images/webMethods.png")}></img> <span className='Brahmāstra'>Customer360&deg;</span></Link>
        <ul className="navbar-nav navbar-nav-left header-links align-self-center">
          {/* Add your other navbar items here */}
        </ul>

        <div className="ml-auto search-form d-none d-md-block">
          <div className="form-group inner-text">
            <input type="search" className="form-control search-bar" value={searchText} onChange={searchChangeHandler} onKeyPress={keyChangeHandler} placeholder={`Search by ${capitalizeFirstLetter(dropdownSelection)}`} ref={searchBar} />
          </div>
          <Dropdown className='dropdown-header-inner-text-box'>
            <Dropdown.Toggle variant="btn btn-outline-secondary dropdown-toggle" id="dropdownMenuButton4">
              {dropdownSelection}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {['tenantName', 'company', 'email'].map((obj) => (
                <Dropdown.Item key={obj} onClick={() => dropClickHandler(obj)}>{obj}</Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
        <ul className="navbar-nav navbar-nav-right">
        </ul>
      </div>
    </nav>
  );
}

export default withRouter(Navbar);
