import React from 'react'
import PaginationRight from './paginationRight'
import PaginationLeft from './paginationLeft';
import PaginationCenter from './paginationMiddle';

export default function PaginationWrapper(props) {
    return (
        <div className={`wmio-pagination-container pagination-container stretch clearfix ${props.className ? props.className:""}`}>
            <PaginationLeft
                count={props.count}
                range={props.range}
            />
            <PaginationCenter
                currentPage={props.currentPage}
                count={props.count}
                onChange={props.onChange}
                limit={props.limit}
                hideOnSinglePage={props.hideOnSinglePage}
            />
            <PaginationRight
                currentPage={props.currentPage}
                count={props.count}
                onChange={props.onChange}
                limit={props.limit}
            />

        </div>
    )
}